import autosize from "autosize"

export default class InputHandler {
  // Singelton Example: private instance and private constructor
  private static instance: InputHandler

  private constructor() {}

  static start() {
    if (!InputHandler.instance) {
      InputHandler.instance = new InputHandler()
      InputHandler.instance.setup()
    }
  }

  private setup() {
    document.addEventListener('turbolinks:load', () => {
      this.initTextareaAutosize()
    })
  }

  // Textarea auto resize
  initTextareaAutosize() {
    autosize(document.querySelectorAll('textarea'))
  }
}
