import { config, dom, library, IconDefinition }
  from '@fortawesome/fontawesome-svg-core'

import * as far_icons from './far_icons'
import * as fas_icons from './fas_icons'
import * as fal_icons from './fal_icons'
import * as fab_icons from './fab_icons'

library.add(...Object.keys(far_icons).map((key)=> {
  return <IconDefinition>(<any>far_icons)[key]
}))

library.add(...Object.keys(fas_icons).map((key)=> {
  return <IconDefinition>(<any>fas_icons)[key]
}))

library.add(...Object.keys(fal_icons).map((key)=> {
  return <IconDefinition>(<any>fal_icons)[key]
}))

library.add(...Object.keys(fab_icons).map((key)=> {
  return <IconDefinition>(<any>fab_icons)[key]
}))

config['mutateApproach'] = 'sync'
dom.watch()
