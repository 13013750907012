import { Controller } from 'stimulus'
import { TimelineLite, ScrollToPlugin } from 'gsap/all'
const plugins = [ScrollToPlugin]

export default class extends Controller {
  readonly imagesTarget!: HTMLElement
  readonly hasImagesTarget!: boolean
  static targets = ['images']

  timeline: TimelineLite = new TimelineLite()

  start() {
    if (this.hasImagesTarget) {
      let speed = this.imagesTarget.scrollWidth / 50
      this.timeline.clear()
          .to(this.imagesTarget, speed,
              { scrollTo: { x: this.imagesTarget.scrollWidth }})
    }
  }

  stop() {
    if (this.hasImagesTarget) {
      this.timeline.clear()
          .to(this.imagesTarget, 0.5, { scrollTo: { x: 0 }})
    }
  }
}
