import { Controller } from 'stimulus'
import { TimelineLite } from 'gsap/TweenMax'
import Modal from 'components/modal_component'
import axios from 'axios'
import { setupAxios } from 'helpers/html_head_helper'

export default class extends Controller {
  readonly emailInputTarget!: HTMLInputElement
  readonly currentPasswordWrapperTarget!: HTMLElement
  static targets = ['emailInput', 'currentPasswordWrapper']
  currentEmail: string = this.data.get('currentEmail')!
  currentPasswordInputVisible: boolean = false

  initialize() {
    setupAxios()
  }

  connect() {
    if (this.emailInputTarget.value == this.currentEmail) {
      this.currentPasswordWrapperTarget.style.display = 'none'
    }
  }

  emailChanged() {
    if (this.emailInputTarget.value != this.currentEmail) {
      this.showCurrentPasswordInput()
    } else {
      this.hideCurrentPasswordInput()
    }
  }

  showCurrentPasswordInput() {
    if (!this.currentPasswordInputVisible) {
      this.currentPasswordInputVisible = true
      let tl = new TimelineLite()
      tl.set(this.currentPasswordWrapperTarget, { display: 'flex',
                                                  overflow: 'hidden' })
        .from(this.currentPasswordWrapperTarget, 0.3, { height: '0px' })
    }
  }

  hideCurrentPasswordInput() {
    if (this.currentPasswordInputVisible) {
      this.currentPasswordInputVisible = false
      let tl = new TimelineLite()
      tl.set(this.currentPasswordWrapperTarget, { overflow: 'hidden' })
        .to(this.currentPasswordWrapperTarget, 0.3, { height: '0px' })
        .set(this.currentPasswordWrapperTarget, { display: 'none',
                                                  clearProps: 'height' })
    }
  }

  destroy(e: Event) {
    e.preventDefault()

    Modal.showModal({
      title: 'Möchtest du deinen Konto wirklich löschen?',
      text:
        `Deine Daten werden bei diesem Vorgang unwiederruflich gelöscht.
         Wenn du dein Konto wirklich löschen möchtest, bestätige dies mit
         deinem Passwort.`,
      type: 'error',
      width: '42rem',
      input: 'password',
      inputValidator: (result) => {
        if(!result) {
          return 'Kein Passwort angegeben'
        }
        return null
      },
      inputPlaceholder: 'Passwort',
      showCancelButton: true,
      backdrop: 'rgba(170, 60, 60, 1)',
      confirmButtonColor: '#D63230',
      confirmButtonText: 'Unwiederruflich Löschen',
      cancelButtonText: 'Abbrechen',
      allowOutsideClick: true,
      allowEscapeKey: true,
      allowEnterKey: false,
      showLoaderOnConfirm: true,
      preConfirm: (password) => {
        return new Promise(() => {
          let data = new FormData
          data.append('user[password]', password)
          axios.patch(this.data.get('destroy-url')!, data)
            .then(() => {
              Modal.showModal({
                type: 'info',
                title: 'Dein konto wurde gelöscht',
                html: "<a href='/'>Zurück zur Startseite</a>",
                backdrop: 'rgba(0, 0, 0, 1)',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showConfirmButton: false
              })
            }).catch((error) => {
              if (error.response.status == 422) {
                Modal.showModal({
                  type: 'error',
                  title: 'Passwort ist falsch!',
                  text: 'Dein Konto wurde nicht gelöscht.'
                })
              } else {
                Modal.showModal({
                  type: 'error',
                  title: 'Ein Fehler ist aufgetreten.',
                  text: error
                })
              }
            })
        })
      }
    })
  }
}
