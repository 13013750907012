import DateTimePickerHandler from "handlers/forms/date_time_picker_handler"
DateTimePickerHandler.start()

import InputHandler from "handlers/forms/input_handler"
InputHandler.start()

import SubmitHandler from "handlers/forms/submit_handler"
SubmitHandler.start()

import Turboforms from "handlers/forms/turboforms"
Turboforms.start()
