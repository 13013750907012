// Helpers for HTML Head

/** retrieve the cookie value of the passed name */
export function getCookieValue(name: string): string | undefined {
  var matches = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')
  return matches ? matches.pop() : undefined;
}

/** extracts the CSRF-Token from the meta tag (if present)
* the meta tag is the one generated by Rails (via <%= csrf_meta_tags %> )*/
export function getCSRFToken(): string | undefined{
  let meta = <HTMLMetaElement>document.querySelector("meta[name=csrf-token]")
  if (meta){
    return meta.content
  }
  else { // happens when CSRF-Protection is off (e.g. in tests)
    return undefined
  }
}

/** sets the CSRF-Token in the meta tag (if present)
* You won't need his very often*/
export function setCSRFToken(token: string): boolean{
  let meta = <HTMLMetaElement>document.querySelector("meta[name=csrf-token]")
  if (meta){
    meta.content = token
    return true
  }
  else { // happens when CSRF-Protection is off (e.g. in tests)
    return false
  }
}

import axios from 'axios'
/** sets the current meta[name=csrf-token] value as default for axios */
export function setupAxios() {
  let meta = <HTMLMetaElement>document.querySelector("meta[name=csrf-token]")
  if (meta){
    axios.defaults.headers.common[ 'X-CSRF-Token'] = meta.content
  }
  else { // happens when CSRF-Protection is off (e.g. in tests)
    console.warn('could not find meta[name=csrf-token] -> '+
                 'Axios will run without CSRF-Header')
  }
  axios.defaults.headers.common['Accept'] = 'application/json'
}
