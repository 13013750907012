import { Controller } from 'stimulus'
import { TimelineLite } from 'gsap/TweenMax'

export default class extends Controller {
  readonly menuTarget!: HTMLElement
  static targets = ['menu']
  open: boolean = false

  mobileNavTrigger(e: Event) {
    e.preventDefault()
    let tl = new TimelineLite()
    if (this.open) {
      tl.to(this.menuTarget, 0.5, { height: 0 }, 'close')
        .to(this.menuTarget, 0.2, { opacity: 0 }, 'close')
        .call(() => { this.menuTarget.classList.remove('open') })
        .set(this.menuTarget, { clearProps: 'height, opacity' })
    } else {
      this.menuTarget.classList.add('open')
      tl.from(this.menuTarget, 0.1, { height: 0 }, 'open')
        .from(this.menuTarget, 0.3, { opacity: 0 }, 'open')
        .set(this.menuTarget, { clearProps: 'height, opacity' })
    }
    this.open = !this.open
  }
}
