import { default as IziToast, IziToastSettings } from 'izitoast'
require('izitoast/dist/css/iziToast')

/**
 * A module for managing toasts.
 */
export namespace ToastComponent {

  /**
   * initializes and shows a Toast.
   * @param { ToastType } type
   * the general Type defines the color, icon and closing behaviour
   * @param { IziToastSettings } settings
   */
  export function showToast(type: ToastType,
                            settings: IziToastSettings): void {
    IziToast.show(mergeDefaults(type, settings))
  }

  ///////////////////////////////////////////////////////////////////////
  ////////////////////////////   private   //////////////////////////////
  ///////////////////////////////////////////////////////////////////////

  function mergeDefaults(type: ToastType | null,
                         settings: IziToastSettings): IziToastSettings {
    let defaultSettings: IziToastSettings = {
      message: '',
      icon: getIconFromType(type),
      overlayClose: true,
      closeOnEscape: true,
      close: true,
      class: type || undefined,
      transitionIn: 'fadeInUp',
      transitionOut: 'fadeOutDown',
      position: 'bottomCenter',
      pauseOnHover: true,
    }
    return Object.assign(defaultSettings, settings)
  }

  function getIconFromType(type: ToastType | null): string {
    switch (type) {
      case 'alert': { return 'fas fa-exclamation-circle' }
      case 'success': { return 'fas fa-check-circle' }
      case 'info': { return 'fas fa-info-circle' }
      case 'notice': { return 'fas fa-info-circle' }
      default: { return '' }
    }
  }

  ///////////////////////////////////////////////////////////////////////
  ////////////////////////////   public types    ////////////////////////
  ///////////////////////////////////////////////////////////////////////

  /**
  * defines the color, and icon
  * you can override every detail by setting the according option manually
  **/
  export type ToastType = 'alert' | 'success' | 'info' | 'notice'
}

export default ToastComponent;
//ES5 window Module (used in styleguide)
if (!(<any>window).Modules){ (<any>window).Modules = {} }
(<any>window).Modules.ToastComponent = ToastComponent