import { Controller } from 'stimulus'

export default class extends Controller {
  readonly formTarget!: HTMLElement
  readonly toggleBtnTarget!: HTMLButtonElement
  static targets = ['form', 'toggleBtn']
  
  showForm() {
    // console.log(this.formTarget)
    // console.log(this.toggleBtnTarget)
    this.formTarget.style.display = "block"
    this.toggleBtnTarget.classList.add("hidden")
  }
}