// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('handlers/rails_ujs_handler')

require("handlers/forms/forms_handler")
require("handlers/stripe")
require("handlers/trix_handler")

require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Polyfills
import "core-js/stable"
import "regenerator-runtime/runtime"
import "@stimulus/polyfills"

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("../controllers", true, /\.ts$/)
application.load(definitionsFromContext(context))

import 'normalize.css'
require('modules/fa_setup')
import 'dropzone/dist/dropzone.css'
import '../stylesheets/base.scss'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
