import { Controller } from 'stimulus'
import { setupAxios } from 'helpers/html_head_helper'
import Sortable from 'sortablejs'
import axios from 'axios'

export default class extends Controller {
  sortable: Sortable
  url = this.data.get('url')!

  initialize() {
    // set crsf header for axios by default
    setupAxios()

    // Sorting
    this.sortable = Sortable.create(this.element, {
      animation: 150,
      ghostClass: 'sorting',
      handle: '.grab',
      onSort: ()=> {
        let data = new FormData
        for (let item of this.sortable.toArray().reverse()) {
          data.append('decks[]', item)
        }
        axios.patch(this.url, data)
      }
    })
  }
}
