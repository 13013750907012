import { Controller } from 'stimulus'
import { TimelineLite } from 'gsap/TweenMax'
import ModalComponent from 'components/modal_component'

export default class extends Controller {
  readonly questionTarget!: HTMLElement
  readonly iconTarget!: HTMLElement
  readonly answerTarget!: HTMLElement
  static targets = ['question', 'icon', 'answer']

  openSupport(e: Event) {
    e.preventDefault()
    ModalComponent.showModal(
      {
        type: 'info',
        html: "<br>Schreib uns eine E-Mail mit deinen Fragen an<h2>support@slidebox.io</h2>Telefonisch sind wir Mo - Fr von 10:00 - 17:00 Uhr erreichbar<h2>030 / 220 117 390</h2>"
      }
    )
  }

  toggle(e: Event) {
    e.preventDefault()
    if (this.state === 'closed') {
      this.state = 'open'
      this.reveal()
    } else if (this.state === 'open') {
      this.state = 'closed'
      this.hide()
    }
  }

  reveal() {
    this.iconTarget.classList.add('up')
    this.questionTarget.classList.add('open')
    let tl = new TimelineLite()
    tl.set(this.answerTarget, { display: 'block' })
      .from(this.answerTarget, 0.3, { height: '0px',
                                      paddingBottom: '0px',
                                      paddingTop: '0px' })
  }

  hide() {
    this.iconTarget.classList.remove('up')
    this.questionTarget.classList.remove('open')
    let tl = new TimelineLite()
    tl.to(this.answerTarget, 0.3, { height: '0px',
                                    paddingBottom: '0px',
                                    paddingTop: '0px' })
      .set(this.answerTarget, { clearProps: 'all' })
  }

  get state() {
    return this.data.get('state')!
  }

  set state(value) {
    this.data.set('state', value)
  }
}
