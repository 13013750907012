import { Controller } from "stimulus"
import * as Cookies from 'js-cookie'

export default class extends Controller {
  static targets = [ 'container' ]

  ok(){
    Cookies.set('allow_cookies', 'yes', { expires: 365 })
    this.fadeOut()
  }

  fadeOut() {
    this.container.style.display = 'none'
  }

  get container() {
    return <HTMLElement>this.targets.find('container')
  }
}
