import { Controller } from 'stimulus'
import axios from 'axios'
import { RequiredHelper } from 'helpers/required_helper'
import * as HTMLHelper from 'helpers/html_helper'
import consumer from 'channels/consumer'
import tippy from 'tippy.js'

type Status = 'converting' | 'conversion_error' | 'ready'
type ErrorType = 'cc_error_none' | 'cc_error_timeout' | 'cc_error_internal' |
                 'cc_error_bad_file' | 'cc_host_overload'
type ChannelMessage = { step_percent: number, step_number: number,
                        cc_error_type: ErrorType, status: Status,
                        step_desc: string, message: string }

export default class extends Controller {
  ////////////////// TARGETS ////////////////////
  readonly previewTarget!: HTMLElement
  readonly progressTarget!: HTMLElement
  static targets = ['preview', 'progress']

  conversionProgressChannel!: ActionCable.Channel
  readyAnimationStarted = false
  channelDisconnected = false
  showChannelDisconnected = false // with delay to channelDisconnected

  connect() {
    console.log('ctrl connect')
    RequiredHelper.checkData(this,
      'deck-id', 'retry-conversion-path', 'preview-slide-path',
      'show-deck-path', 'status', 'cc-error-type', 'step-number'
    )
    this.updateProgress()
    
    const received = (msg: ChannelMessage) => {
      if (msg.cc_error_type != 'cc_error_none') {
        console.log(msg)
      }
      this.status = msg.status
      this.stepNumber = msg.step_number
      this.stepPercent = msg.step_percent
      this.ccErrorType = msg.cc_error_type
      this.stepDesc = msg.step_desc
      this.message = msg.message
      this.updateProgress()
    }
    const disconnected = ()=> {
      console.log('channel disconnect')
      this.channelDisconnected = true
      setTimeout(()=>{
        if (this.channelDisconnected) {
          this.showChannelDisconnected = true
          this.updateProgress()
        }
      }, 6000)
    }
    const connected = ()=> {
      console.log('channel connect')
      this.channelDisconnected = false
      this.showChannelDisconnected = false
      this.updateProgress()
    }
    this.conversionProgressChannel = consumer.subscriptions.create(
      { channel: 'ConversionProgressChannel',
        deck_id: this.data.get('deck-id') },
      {
        initialized() {},
        connected: connected,
        disconnected: disconnected,
        rejected() {},
        received: received
      }
    )
  }

  disconnect() {
    console.log('ctrl disconnect')
    this.conversionProgressChannel.unsubscribe()
  }

  retryConversion(){
    axios.patch(this.data.get('retry-conversion-path')!)
  }

  // #########################        private        ########################

  private updateProgress(){
    // suppress updateProgress when already in ready animation
    if (this.readyAnimationStarted) { return }
    this.updateSegments()
    this.updateErrors()
    if (this.status == 'ready') {
      this.readyAnimation()
      this.conversionProgressChannel.unsubscribe()
    }
  }

  private updateSegments(){
    const svg = HTMLHelper.assureSVGElement(
      this.previewTarget.querySelector('.ring')
    )
    let stepNumber = this.stepNumber
    for (let i = 1; i <= 5; i++){
      let segment = <SVGPathElement>svg.querySelector(`path.segment[id='${i}']`)
      let border = <SVGPathElement>svg.querySelector(`path.border[id='${i}']`)
      if (stepNumber > i) {
        segment.style.opacity = '1' 
        border.classList.toggle('started', true)
      } 
      else if (stepNumber == i) {
        segment.style.opacity = (this.stepPercent * 0.01).toString()
        border.classList.toggle('started', true)
      }
      else {
        segment.style.opacity = '0'
        border.classList.toggle('started', false)
      }
    }
  }

  private updateErrors(){
    const hasErrors = this.status == 'conversion_error'
    this.previewTarget.classList.toggle('error', hasErrors)
    this.previewTarget.querySelector('#timeout')!.classList.toggle(
      'show', this.ccErrorType == 'cc_error_timeout'
    )
    this.previewTarget.querySelector('#badfile')!.classList.toggle(
      'show', this.ccErrorType == 'cc_error_bad_file'
    )
    this.previewTarget.querySelector('#internal')!.classList.toggle(
      'show', this.ccErrorType == 'cc_error_internal'
    )
    this.previewTarget.querySelector('#connection')!.classList.toggle(
      'show', this.showChannelDisconnected
    )
    this.previewTarget.querySelector('#hostoverload')!.classList.toggle(
      'show', this.ccErrorType == 'cc_host_overload'
    )
    if (this.channelDisconnected) {
      this.errorTippy('Verbindungsfehler<br>bitte Seite neu laden')
    } else if (this.ccErrorType == 'cc_error_timeout') {
      this.errorTippy('Zeitüberschreitung<br>nochmal versuchen')
    } else if (this.ccErrorType == 'cc_host_overload') {
      this.errorTippy('Server ausgelastet<br>nochmal versuchen')
    } else if (this.ccErrorType == 'cc_error_bad_file') {
      this.errorTippy('Datei fehlerhaft')
    } else if (this.ccErrorType == 'cc_error_internal') {
      this.errorTippy('Serverfehler ... entschuldigung')
    } else {
      let currentTippy = this.progressTarget['_tippy']
      if (currentTippy) { (<any>currentTippy).destroy() }
    }

    const canRetry = this.ccErrorType == 'cc_host_overload' ||
                     this.ccErrorType == 'cc_error_timeout' ||
                     this.ccErrorType == 'cc_error_internal' // TODO remove
    if (canRetry) {
      this.progressTarget.removeAttribute('disabled')
    } else {
      this.progressTarget.setAttribute('disabled', '')
    }
  }

  private errorTippy(content: string){
    tippy(this.progressTarget,
      { arrow: true, theme: 'danger', content: content })
  }

  private readyAnimation() {
    this.previewTarget.classList.toggle('ready', true)
    this.readyAnimationStarted = true
    setTimeout(() => {
      //fetch the first slide and show it
      axios.get(this.data.get('preview-slide-path')!)
          .then((response) =>{
            const previewImgUrl = response.data['img_url']
            const imgElem = document.createElement('img')
            imgElem.setAttribute('src', previewImgUrl)
            const linkElem = document.createElement('a')
            linkElem.setAttribute('href', this.data.get('show-deck-path')!)
            linkElem.setAttribute('target', '_blank')
            linkElem.prepend(imgElem)
            this.previewTarget.classList.toggle('switch-to-img', true)
            this.previewTarget.append(linkElem)
          })        
    }, 1000);
  }

  // #########################      persistence      ########################

  get status(): Status {
    return <Status>this.data.get("status")!
  }
  set status(value: Status) {
    this.data.set("status", value)
  }
  get ccErrorType(): ErrorType {
    return <ErrorType>this.data.get("cc-error-type")!
  }
  set ccErrorType(value: ErrorType) {
    this.data.set("cc-error-type", value)
  }
  get stepNumber(): number {
    return parseInt(this.data.get("step-number")!)
  }
  set stepNumber(value: number) {
    this.data.set("step-number", value.toString())
  }
  get stepPercent(): number {
    return parseInt(this.data.get("step-percent") || '0')
  }
  set stepPercent(value: number){
    this.data.set("step-percent", value.toString())
  }
  get stepDesc(): string {
    return this.data.get("step-desc") || ''
  }
  set stepDesc(value: string){
    this.data.set("step-desc", value)
  }
  get message(): string {
    return this.data.get("message") || ''
  }
  set message(value: string){
    this.data.set("message", value)
  }
}