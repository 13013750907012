import Swal, { SweetAlertOptions } from 'sweetalert2'
export { SweetAlertType } from 'sweetalert2'

/**
 * A module for managing toasts.
 */
export namespace ModalComponent {

  export function showModal(options: SweetAlertOptions) {
    const Modal = Swal.mixin({
      // CUSTOM DEFAULT OPTIONS
      // toast: true,
      // position: 'top-end',
      // showConfirmButton: false,
      // timer: 3000
    })
    return Modal.fire(options)
  }
}

export default ModalComponent;
//ES5 window Module (used in styleguide)
if (!(<any>window).Modules){ (<any>window).Modules = {} }
(<any>window).Modules.ToastComponent = ModalComponent
