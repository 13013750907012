import { Controller } from 'stimulus'
import Modal from 'components/modal_component'
import Turbolinks from 'turbolinks'

export default class extends Controller {
  stripe!: stripe.Stripe
  clientSecret!: string
  readonly confirmTarget!: HTMLButtonElement
  static targets = ['confirm']

  confirm(e: Event) {
    e.preventDefault()
    this.stripe = Stripe(this.data.get('pubkey')!, { locale: 'de' })
    this.clientSecret = this.data.get('client-secret')!
    this.confirmTarget.disabled = true

    this.stripe.handleCardPayment(this.clientSecret).then((result) => {
      if (result.error) {
        Modal.showModal({
          title: 'Autorisierung fehlgeschlagen',
          text: 'Bitte veruche es nochmal',
          type: 'error'
        }).then(() => {
          Turbolinks.visit(
            window.location.pathname, { action: 'replace' }
          )
        })
      } else {
        Turbolinks.visit(this.data.get('success-url')!)
      }
    });
  }
}
