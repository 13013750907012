export default class SubmitHandler {

  // Singelton Example: private instance and private constructor
  private static instance: SubmitHandler

  private constructor() {}

  static start() {
    if (!SubmitHandler.instance) {
      SubmitHandler.instance = new SubmitHandler()
      SubmitHandler.instance.setup()
    }
  }

  private setup() {
    document.addEventListener('turbolinks:load', () => {
      for (let i = 0; i < document.forms.length; i++) {
        let form = document.forms[i]
        form.onsubmit = () => {
          let submitButton = form.querySelector('button[type=submit]')
          if (submitButton) {
            this.buttonDisabler(submitButton)
          }
        }
      }
    })
  }

  buttonDisabler(submitButton) {
    let disableContent = submitButton.dataset.disableContent
    submitButton.disabled = true
    if (disableContent != undefined) {
      submitButton.innerHTML = disableContent
    } else {
      submitButton.classList.add('loading')
    }
  }
}
