import { Controller } from 'stimulus'
import ProgressBar from 'progressbar.js'
import Turbolinks from 'turbolinks'
import Chart from 'chart.js'
Chart.defaults.global.defaultFontFamily = "'Visuelt Pro', sans-serif"
Chart.defaults.global.defaultFontColor = '#485057'

export default class extends Controller {
  readonly circleProgressTargets!: HTMLElement[]
  readonly lineProgressTargets!: HTMLElement[]
  readonly visitDataTarget!: HTMLCanvasElement
  static targets = ['circleProgress', 'lineProgress', 'visitData']

  connect() {
    for (let circleProgressElement of this.circleProgressTargets) {
      this.initCircleProgress(circleProgressElement)
    }
    for (let lineProgressElement of this.lineProgressTargets) {
      this.initLineProgress(lineProgressElement)
    }
    this.initVisitData(this.visitDataTarget)
  }

  changeBox(e: Event) {
    Turbolinks.visit((<HTMLOptionElement>e.target)!.value)
  }

  initCircleProgress(el: HTMLElement) {
    let circleBar = new ProgressBar.Circle(el, {
      strokeWidth: 4,
      easing: 'easeInOut',
      duration: 1400,
      trailColor: '#d9dcde',
      trailWidth: 1,
      svgStyle: null
    })
    circleBar.path.style.strokeLinecap = 'round'
    circleBar.animate(parseInt(el.dataset.amount!)/100)
  }

  initLineProgress(el: HTMLElement) {
    let lineBar = new ProgressBar.Line(el, {
      strokeWidth: 1.0,
      color: '#F39237',
      trailColor: '#d9dcde',
      trailWidth: 0.3
    })
    lineBar.animate(el.dataset.score)
  }

  initVisitData(ctx: HTMLCanvasElement) {
    new Chart(ctx, {
      type: 'line',
      data: {
        labels: JSON.parse(ctx.dataset.labels!),
        datasets: [{
          data: JSON.parse(ctx.dataset.visits!),
          label: 'Einzelbesucher',
          color: 'rgba(60, 145, 230, 1)',
          backgroundColor: 'rgba(60, 145, 230, 0.2)',
          borderColor: 'rgba(60, 145, 230, 1)',
          borderWidth: 5,
          hoverBorderWidth: 5,
          pointBackgroundColor: '#FFFFFF',
          pointBorderColor: 'rgba(60, 145, 230, 1)',
          pointBorderWidth: 5,
          pointHoverBorderWidth: 5,
          pointRadius: 6,
          pointHoverRadius: 6,
        }, {
          data: JSON.parse(ctx.dataset.views!),
          label: 'Dokumentaufrufe',
          color: '#F39237',
          backgroundColor: 'rgba(243, 146, 55, 0.2)',
          borderColor: '#F39237',
          borderWidth: 5,
          hoverBorderWidth: 5,
          pointBackgroundColor: '#FFFFFF',
          pointBorderColor: '#F39237',
          pointBorderWidth: 5,
          pointHoverBorderWidth: 5,
          pointRadius: 6,
          pointHoverRadius: 6,
        }]
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: true
        },
        scales: {
          yAxes: [{
            gridLines: { color: '#d9dcde', lineWidth: 1.5 },
            ticks: { beginAtZero: true, precision: 0 }
          }],
          xAxes: [{
            gridLines: { color: '#d9dcde', lineWidth: 1.5 }
          }]
        }
      }
    })
  }
}
