import { Controller } from 'stimulus'

export default class extends Controller {
  readonly countryTarget!: HTMLSelectElement
  readonly vatWrapperTarget!: HTMLElement
  readonly vatInputTarget!: HTMLInputElement
  static targets = ['country', 'vatWrapper', 'vatInput']

  selectedCountry: string = '--'
  countryDisplay = <HTMLElement>this.vatWrapperTarget.
                    getElementsByClassName('input-group-prepend')[0]
  countryFormats = {
    'AT': 'U12345678',
    'BE': '1234567890, 0123456789',
    'BG': '123456789, 1234567890',
    'HR': '12345678901',
    'CY': '12345678X',
    'CZ': '12345678, 123456789, 1234567890',
    'DK': '12345678',
    'EE': '123456789',
    'FI': '12345678',
    'FR': '12345678901, X1234567890, 1X123456789, XX123456789',
    'DE': '123456789',
    'EL': '123456789',
    'HU': '12345678',
    'IE': '1234567X, 1234567XX, 1X34567X',
    'IT': '12345678901',
    'LV': '12345678901',
    'LT': '123456789, 123456789012',
    'LU': '12345678',
    'MT': '12345678',
    'NL': '123456789B01, 123456789B02',
    'PL': '1234567890',
    'PT': '123456789',
    'RO': '1234567890',
    'SK': '1234567890',
    'SI': '12345678',
    'ES': 'X12345678, 12345678X, X1234567X',
    'SE': '123456789012'
  }
  connect() {
    this.selectedCountry = this.data.get('selected-country')!
    this.setPlaceholder()
  }

  setPlaceholder() {
    this.vatInputTarget.placeholder = 'Format: ' +
      this.countryFormats[this.selectedCountry]
  }

  changeCountry() {
    this.selectedCountry =
      this.countryTarget.options[this.countryTarget.selectedIndex].value
    this.countryDisplay.innerHTML = this.selectedCountry
    this.setPlaceholder()
  }

  sanitize() {
    // Get cursor positon
    let ss = this.vatInputTarget.selectionStart
    let se = this.vatInputTarget.selectionEnd
    // Sanitize
    let sanitzed = this.vatInputTarget.value.replace(/[^a-zA-Z0-9]/g,'')
    sanitzed = sanitzed.toUpperCase()
    this.vatInputTarget.value = sanitzed
    // Set cursor positon
    this.vatInputTarget.selectionStart = ss
    this.vatInputTarget.selectionEnd = se
  }
}
