import { Controller } from 'stimulus'

export default class extends Controller {
  readonly loadingTarget!: HTMLSpanElement
  readonly resendConfirmLinkTarget!: HTMLAnchorElement
  static targets = ['loading', 'resendConfirmLink']

  connect() {
    this.loadingTarget.style.display = 'none'
  }

  resendConfirmMail(e: Event) {
    this.loadingTarget.style.display = 'inline'
    setTimeout(() => {
      this.resendConfirmLinkTarget.removeAttribute('href')
    }, 0)
  }
}
