import { Controller } from "stimulus"
import tippy from 'tippy.js'
//import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light-border.css'

/**
 * A class for managing tooltips via Tippy.js
 */
export default class TippyController extends Controller {
  /*
  * note:
  * Tippy.js has already a settings system based on data-tippy-attributes.
  * So, we call this controller TippyController, so it can be used
  * like every controller, but inside using the tippy.js interface :)
  */
  tippy
  observer: MutationObserver

  initialize() {
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type == "attributes") {
          if (mutation.attributeName == "data-title") {
            this.tippy.setContent(this.element.getAttribute('data-title'))
          } else if (mutation.attributeName == "title") {
            this.tippy.setContent(this.element.getAttribute('title'))
          }
        }
      })
    })
  }

  connect() {
    let content = this.element.getAttribute('title') ||
                  this.element.getAttribute('data-title')
    if (!content) { return }
    this.initTippy(content)
    this.observer.observe(this.element, { attributes: true })
  }

  disconnect() {
    this.observer.disconnect()
    this.tippy.destroy()
  }

  initTippy(content: string) {
    this.tippy = tippy(this.element, {
      arrow: true,
      content: content,
      // do not change hideOnClick!
      hideOnClick: false,
      // trigger: 'mouseover',
      delay: 300,
      popperOptions: {
        modifiers: {
          preventOverflow: {
            boundariesElement: 'window'
          }
        }
      }
    })
  }
}
