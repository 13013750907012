import { Controller } from 'stimulus'
import Modal from 'components/modal_component'
import Turbolinks from 'turbolinks'
import axios from 'axios'
import { setupAxios } from 'helpers/html_head_helper'


export default class extends Controller {
  initialize() {
    setupAxios()
  }

  cancel(e: Event) {
    e.preventDefault()
    Modal.showModal({
      title: 'Möchtest du dein Abo wirklich kündigen?',
      html:
        `Nach der Kündigung läuft dein Abo noch bis zum
         ${this.data.get('end-date')}. Im Anschluss werden alle
         Dokumente, bis auf die ersten zehn, deaktiviert. Wenn das
         Abo nicht innerhalb der nächsten drei darauffolgenden
         Monate erneuert wird, werden deine Dokumente dauerhaft
         gelöscht. Dies passiert zum ${this.data.get('delete-date')}.
         <br>
         Du kannst dein Abo jederzeit erneuern.
         </br></br>
         Wenn du dein Abo wirklich kündigen möchtest, bestätige
         dies durch Eingabe deiner Email Adresse.`,
      type: 'error',
      input: 'text',
      inputPlaceholder: this.data.get('email')!,
      inputValidator: (result) => {
        if (!result) {
          return 'Keine Email Adresse angegeben'
        } else if (result != this.data.get('email')) {
          return 'Falsche Email Adresse'
        } else {
          return null
        }
      },
      showCancelButton: true,
      confirmButtonColor: '#D63230',
      confirmButtonText: 'Abo kündigen',
      cancelButtonText: 'Abbrechen',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return new Promise(() => {
          axios.delete(this.data.get('url')!)
            .then((response) => {
              Modal.showModal({
                type: 'info',
                title: 'Dein Abo wurde gekündigt!',
                text:
                  `Du kannst es jederzeit erneuern.`
              }).then(() => {
                Turbolinks.visit(response.data.location)
              })
            })
            .catch((error) => {
              Modal.showModal({
                type: 'error',
                title: 'Ein Fehler ist aufgetreten.',
                text: error
              })
            })
        })
      }
    })
  }

  reactivate(e:Event) {
    e.preventDefault()
    Modal.showModal({
      title: 'Abo erneuern?',
      text: 'Möchtest du die Kündigung deines Abos zurücknehmen?',
      type: 'question',
      showCancelButton: true,
      confirmButtonText: 'Abo erneuern!',
      cancelButtonText: 'Abbrechen',
      confirmButtonColor: '#61ce91',
      preConfirm: () => {
        return new Promise(() => {
          axios.patch(this.data.get('url')!)
            .then((response) => {
              Modal.showModal({
                title: 'Dein Abo wurde erfolgreich erneuert.',
                type: 'success'
              }).then(() => {
                Turbolinks.visit(response.data.location)
              })
            })
            .catch((error) => {
              Modal.showModal({
                title: 'Ein Fehler ist aufgetreten',
                text: error,
                type: 'error'
              })
            })
        })
      }
    })
  }
}
