import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'link' ]
  readonly linkTargets!: HTMLLinkElement[]

  initialize() {
    console.log(this.linkTargets)
  }

  clickLink(e: Event) {
    this.linkTargets.forEach((linkTarget) => {
      linkTarget.classList.remove('active')
    });
    (<HTMLLinkElement>e.target).classList.add('active')
  }
}
