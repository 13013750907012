import { Controller } from 'stimulus'
import Modal from 'components/modal_component'
import Turbolinks from 'turbolinks'

export default class extends Controller {
  readonly cycleInputTarget!: HTMLInputElement
  static targets = ['cycleInput']

  selectPlan(e: Event) {
    e.preventDefault()
    let type_descriptor = (<HTMLElement>e.currentTarget).getAttribute('name')
    Modal.showModal({
      type: 'success',
      title:
        `Vielen Dank, dass du dich für ein
         ${type_descriptor} entschieden hast.`,
      html:
        `
         <p>
          Um fortzufahren melde dich bitte zunächst kostenlos an.
          Im Anschluss kannst du den Upgradeprozess starten.
         </p>`,
      confirmButtonText: 'Jetzt anmelden',
      confirmButtonColor: '#61ce91'
    }).then((result)=>{
      if (result.value) {
        Turbolinks.visit(
          this.data.get('register-url')
        )
      }
    })
  }

  cycleToggle(e: any) {
    let monthlyContainer = document.getElementById('pricing-monthly')!
    let yearlyContainer = document.getElementById('pricing-yearly')!
    if (this.cycleInputTarget.checked) {
      monthlyContainer.classList.add('show')
      monthlyContainer.classList.remove('hide')
      yearlyContainer.classList.add('hide')
      yearlyContainer.classList.remove('show')
    } else {
      monthlyContainer.classList.remove('show')
      monthlyContainer.classList.add('hide')
      yearlyContainer.classList.remove('hide')
      yearlyContainer.classList.add('show')
    }
  }
}
