import { Controller } from 'stimulus'
import { TimelineLite } from 'gsap/TweenMax'
import Picker from 'vanilla-picker'

export default class extends Controller {
  ////////////////// TARGETS ////////////////////
  readonly embedWhitelistedTarget: HTMLInputElement
  readonly domainTargets: HTMLElement[]
  readonly usePasswordTarget: HTMLInputElement
  readonly passwordTarget: HTMLInputElement
  readonly passwordTypeTarget: HTMLSelectElement
  readonly passwordWhitelistTarget: HTMLDivElement
  readonly documentInputTargets: HTMLInputElement[]
  readonly passwordDescriptionTarget: HTMLTextAreaElement
  readonly useLeadsTarget: HTMLInputElement
  readonly leadTypeTarget: HTMLSelectElement
  readonly leadDescriptionTarget: HTMLSelectElement
  readonly leadsOptionalTarget: HTMLInputElement
  readonly previewTarget: HTMLDivElement
  static targets = ['embedWhitelisted', 'embedWhitelist', 'domain',
                    'usePassword', 'password', 'passwordType',
                    'passwordWhitelist', 'documentInput',
                    'passwordDescription', 'useLeads','leadType',
                    'leadDescription', 'leadsOptional', 'preview']

  colorPicker: Picker

  connect() {
    this.updateEmbedSettings();
    this.updatePasswordSettings();
    this.updateLeadSettings();
    this.toggleWhitelistVisibility();
    (<HTMLFormElement>this.element).addEventListener('keydown', (e) => {
      if (e.key == 'Enter') {
        let src = (e.srcElement || e.target)!
        let tagName = src['tagName'].toLowerCase()
        // no form submission
        if (tagName != "trix-editor") {
          if (e.preventDefault) { e.preventDefault() }
          else { e.returnValue = false }
        }
        // Enter on domain input generates a new one below
        if (tagName == 'input' &&
            (<HTMLInputElement>src).name == "box[embed_whitelist][]") {
          this.addNewDomainField(
            (<HTMLElement>src).closest('.domain')!, false
          )
        }
      }
      if (e.key == 'Backspace' || e.key == 'Delete') {
        let src = (e.srcElement || e.target)!
        // on empty domain input removes it
        if (src['tagName'].toLowerCase() == 'input') {
          let input = <HTMLInputElement>src
          if (input.name == "box[embed_whitelist][]" && input.value == '') {
            if (this.domainTargets.length > 2) {
              let toRemove = input.closest('.domain')!
              let index = this.domainTargets.indexOf(<HTMLElement>toRemove)
              if (index > 0) { index = index - 1 }
              e.preventDefault()
              this.domainTargets[index].querySelector('input')!.focus()
              this.removeDomainField(toRemove)
            }
          }
        }
      }
    })
  }

  updateEmbedSettings() {
    let enabledInputs = this.embedWhitelistedTarget.checked
    this.domainTargets.forEach((domain)=>{
      if (enabledInputs) {
        domain.classList.remove('disabled')
        domain.querySelector('.input')!.classList.remove('disabled')
        domain.querySelector('input')!.removeAttribute('disabled')
        domain.querySelector('button')!.removeAttribute('disabled')
        // domain.querySelectorAll('button').forEach((el)=>{
        //   el.removeAttribute('disabled')
        // })
      }
      else {
        domain.classList.add('disabled')
        domain.querySelector('.input')!.classList.add('disabled')
        domain.querySelector('input')!.setAttribute('disabled', '')
        domain.querySelector('button')!.setAttribute('disabled', '')
        // domain.querySelectorAll('button').forEach((el)=>{
        //   el.setAttribute('disabled', '')
        // })
      }
    })
  }

  /////////// VISIBILITY OF PASSWORD WHITELIST
  passwordTypeChanged() {
    this.toggleWhitelistVisibility()
  }
  whitelistTimeline = new TimelineLite
  whitelistAnimated = false
  toggleWhitelistVisibility() {
    let passwordType = this.passwordTypeTarget.options
                           [this.passwordTypeTarget.selectedIndex].value
    if (passwordType == 'box') {
      if (this.whitelistAnimated) {
        this.whitelistTimeline.clear()
        this.whitelistTimeline.to(
          this.passwordWhitelistTarget, 0.3, {
            height: '0px'
          }
        ).set(
          this.passwordWhitelistTarget, {
            display: 'none',
            clearProps: 'height'
          })
      } else {
        this.whitelistTimeline.set(
          this.passwordWhitelistTarget, {
            display: 'none'
          }
        )
        this.whitelistAnimated = true
      }
    } else if (passwordType == 'deck') {
      if (this.whitelistAnimated) {
        this.whitelistTimeline.clear()
        this.whitelistTimeline.set(
          this.passwordWhitelistTarget, {
            clearProps: 'all'
          }
        ).from(
          this.passwordWhitelistTarget, 0.3, {
            height: '0px'
          }
        )
      } else {
        this.whitelistAnimated = true
      }
    }
  }

  updatePasswordSettings() {
    if (this.usePasswordTarget.checked) {
      this.passwordTarget.removeAttribute('disabled')
      this.passwordTarget.parentElement!.classList.remove('disabled')
      this.passwordTypeTarget.removeAttribute('disabled')
      this.passwordTypeTarget.parentElement!.classList.remove('disabled')
      this.passwordDescriptionTarget.removeAttribute('disabled')
      this.passwordDescriptionTarget.parentElement!.classList.remove('disabled')
      this.passwordWhitelistTarget.classList.remove('disabled')
      for (let input of this.documentInputTargets) {
        input.disabled = false
      }
    }
    else {
      this.passwordTarget.setAttribute('disabled', '')
      this.passwordTarget.parentElement!.classList.add('disabled')
      this.passwordTypeTarget.setAttribute('disabled', '')
      this.passwordTypeTarget.parentElement!.classList.add('disabled')
      this.passwordDescriptionTarget.setAttribute('disabled', '')
      this.passwordDescriptionTarget.parentElement!.classList.add('disabled')
      this.passwordWhitelistTarget.classList.add('disabled')
      for (let input of this.documentInputTargets) {
        input.disabled = true
      }
    }
  }

  uncheckDocumentList(e: Event) {
    e.preventDefault()
    for (let input of this.documentInputTargets) {
      input.checked = true
    }
  }

  checkDocumentList(e: Event) {
    e.preventDefault()
    for (let input of this.documentInputTargets) {
      input.checked = false
    }
  }

  updateLeadSettings() {
    if (this.useLeadsTarget.checked) {
      this.leadTypeTarget.removeAttribute('disabled')
      this.leadTypeTarget.parentElement!.classList.remove('disabled')
      this.leadsOptionalTarget.removeAttribute('disabled')
      this.leadsOptionalTarget.parentElement!.classList.remove('disabled')
      this.leadDescriptionTarget.removeAttribute('disabled')
      this.leadDescriptionTarget.parentElement!.classList.remove('disabled')
    }
    else {
      this.leadTypeTarget.setAttribute('disabled', '')
      this.leadTypeTarget.parentElement!.classList.add('disabled')
      this.leadsOptionalTarget.setAttribute('disabled', '')
      this.leadsOptionalTarget.parentElement!.classList.add('disabled')
      this.leadDescriptionTarget.setAttribute('disabled', '')
      this.leadDescriptionTarget.parentElement!.classList.add('disabled')
    }
  }

  showSearchFieldChanged(event: Event) {
    let inputEl = <HTMLInputElement>event.target!
    let searchField =
      <HTMLElement>this.previewTarget.querySelector('.search-field')!
    searchField.style.display = inputEl.checked ? 'block' : 'none'
  }

  bgColorChanged(event: Event) {
    let inputEl = <HTMLInputElement>event.target!
    this.previewTarget.style.background = inputEl.value
  }

  fontColorChanged(event: Event) {
    let inputEl = <HTMLInputElement>event.target!
    this.previewTarget.style.color = inputEl.value
  }

  changeColor(event: Event) {
    event.preventDefault()
    let clickedEl = <HTMLElement>event.currentTarget!
    let parentEl = clickedEl.parentElement!
    let buttonEl = parentEl.querySelector('button')!
    let inputEl = parentEl.querySelector('input')!
    let changeFn = (color) => {
      inputEl.value = color.hex
      inputEl.dispatchEvent(new Event('input', { bubbles: true }))
      buttonEl.style.color = color.hex
    }
    if (!this.colorPicker) {
      this.colorPicker = new Picker({
        parent: parentEl,
        color: inputEl.value,
        popup: 'bottom',
        onChange: changeFn
      })
    } else {
      this.colorPicker.movePopup({
        parent: parentEl,
        color: inputEl.value,
        onChange: changeFn
      },
      true)
    }
  }

  removeDomain(event: Event) {
    event.preventDefault()
    let btn = <HTMLButtonElement>event.currentTarget
    this.removeDomainField(btn.closest('.domain')!)
  }

  addDomain(event: Event) {
    let btn = <HTMLButtonElement>event.currentTarget
    let container = <HTMLElement>btn.closest('.domain')!
    this.addNewDomainField(container, true)
  }

  toggleInfo(event: Event) {
    let btn = <HTMLButtonElement>event.currentTarget
    let container = <HTMLElement>btn.closest('.input-with-info')!
    let info = container.querySelector('.info')
    if (container.classList.contains('show-info')) {
      new TimelineLite()
        .to(info, 0.3, { height: '0px', paddingTop: '0px', paddingBottom: '0px',
                         marginTop: '0px', marginBottom: '0px',
                         onComplete: ()=> {
                           container.classList.remove('show-info');
                           (<HTMLElement>info).style.height = null
                         } })
        .set(info, { clearProps: 'all' })
    }
    else {
      container.classList.add('show-info')
      new TimelineLite()
        .set(info, { clearProps: 'all' })
        .from(info, 0.3, { height: '0px', paddingTop: '0px',
                           paddingBottom: '0px', marginTop: '0px',
                           marginBottom: '0px' })
    }
  }

  private addNewDomainField(sourceField: Element, before: boolean) {
    let clone = <Element>sourceField.cloneNode(true)
    clone.classList.remove('as-plus-btn')
    clone.querySelector('input')!.value = ''
    let addBefore = before ? sourceField : sourceField.nextSibling
    sourceField.parentElement!.insertBefore(clone, addBefore)
    clone.querySelector('input')!.focus()
    new TimelineLite()
      .set(clone, { overflow: 'hidden' })
      .from(clone, 0.3, { height: '0px', 'margin-bottom': '0px' })
  }

  private removeDomainField(field: Element) {
    new TimelineLite()
      .set(field, { overflow: 'hidden' })
      .to(field, 0.3, { height: '0px', 'margin-bottom': '0px',
                        onComplete: ()=>field.remove() })
  }
}
