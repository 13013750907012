import Turbolinks from 'turbolinks'

 export default class Turboforms {

   // Singleton Example: private instance and private constructor
  private static instance: Turboforms

  private constructor() {}

  static getInstance(): Turboforms {
    if (!Turboforms.instance){
      Turboforms.instance = new Turboforms()
    }
    return Turboforms.instance
  }

  static start() {
    Turboforms.getInstance().setup()
  }

  private setup() {
    let boundHandler = this.replaceContentHandler.bind(this)
    document.removeEventListener('ajax:error', boundHandler)
    document.addEventListener('ajax:error', boundHandler)
    document.removeEventListener('ajax:success', boundHandler)
    document.addEventListener('ajax:success', boundHandler)
  }

  replaceContentHandler(event: Event) {
    if (!this.checkReplacementPrecondition(event)) { return }
    Turbolinks.clearCache()
    this.replaceTaggedElements(<CustomEvent>event)
    this.replaceForms(<CustomEvent>event)
    Turbolinks.dispatch("turbolinks:load")
  }

  checkReplacementPrecondition(event: Event): boolean {
    // ensure custom event (just to be sure)
    if (typeof(event['detail']) === 'undefined') { return false }
    let detail = (<CustomEvent>event).detail
    let newDoc = detail[0]
    // ensure the event carries an HTML document
    if (!newDoc || newDoc.nodeName !== '#document') { return false }
    // only replace at certain HTTP statuses
    let status = (<XMLHttpRequest>detail[2]).status
    if (event.type == 'ajax:error') {
      if (status == 422) { return true }
    }
    else {
      if (status == 200) { return true }
    }
    return false
  }

  replaceTaggedElements(event: CustomEvent) {
    const sourceDoc = <Document>event.detail[0]
    // get all elements with data-turboforms-update attribute,
    // which are no forms (handled by #replaceForms)
    const sourceElems =
      Array.from(sourceDoc.querySelectorAll('[data-turboforms-update]'))
           .filter((elem)=> { return elem.nodeName.toLowerCase() != 'form' })
    const destinationElems =
      Array.from(document.querySelectorAll('[data-turboforms-update]'))
           .filter((elem)=> { return elem.nodeName.toLowerCase() != 'form' })
    const elemMatchMatrix =
      this.generateElemMatchMatrix(<HTMLElement[]>sourceElems,
                                   <HTMLElement[]>destinationElems)
    const status = this.performReplacement(sourceElems, destinationElems,
                                           elemMatchMatrix)
    if (status == 'ambiguous') {
      console.warn("Ambiguous elements detected.\n"+
                   "The replacement may be incorrect.\nYou "+
                   "should add unique ids or classes to the elements")
    }
    else if (status == 'notFound') {
      console.error("No matching replace-element was found in the response.")
    }
  }

  replaceForms(event: CustomEvent) {
    const sentForm = event.target
    const sourceDoc = <Document>event.detail[0]
    const sourceForms = Array.from(sourceDoc.querySelectorAll('form'))
    // collect all forms in document that are either the sent form or
    // tagged with data-turboforms-update
    const destinationForms =
          Array.from(document.querySelectorAll('form')).filter((form)=>{
      return typeof(form.dataset['turboformsUpdate']) !== 'undefined' ||
             form === sentForm
    })
    const formMatchMatrix =
      this.generateFormMatchMatrix(sourceForms, destinationForms)
    const status = this.performReplacement(sourceForms, destinationForms,
                                           formMatchMatrix)
    if (status == 'ambiguous') {
      console.warn("ambiguous forms detected.\n"+
                  "The form replacement may be incorrect.\nYou "+
                  "should add a hidden field with a unique value to the "+
                  "forms,\nor make them otherwise distinct by the content, "+
                  "they send to the server")
    }
    else if (status == 'notFound') {
      console.error("No matching replace-form was found in the response.")
    }
  }

   // replaces the passed elements (source->destination) using the passed
  // rank_matrix. There can be the following results (return value)
  // ok: all ok
  // ambiguous: ambiguous match (The replacement matching is not obvious)
  // notFound: missing element for replacement
  //           (when the sources are fewer then the destinations)
  performReplacement(sources: Element[], destinations: Element[],
                     rankMatrix: number[][]): 'ok' | 'ambiguous' | 'notFound' {
    if (rankMatrix.length == 0) { return 'ok' }
    let isAmbiguous = false
    let counter = 0
    while (counter < destinations.length) {
      counter++
      // next replacement is picked by max in matrix
      let [si, di] = this.getMaxInMatrix(rankMatrix)
      if (si < 0) { return 'notFound' } // no max found
      else {
        isAmbiguous = isAmbiguous || this.isAmbiguous(rankMatrix, si, di)
        this.clearRowAndColumn(rankMatrix, si, di)
        // finally replace element
        destinations[di].innerHTML = sources[si].innerHTML
      }
    }
    return isAmbiguous ? 'ambiguous' : 'ok'
  }

   getMaxInMatrix(matrix: (number | null)[][]): number[] {
    let maxSI = -1
    let maxDI = -1
    let max = -1
    let si = 0
    while (si < matrix.length) {
      let row = matrix[si]
      let di = 0
      while (di < row.length) {
        let v = row[di]
        if (v != null && v > max) {
          maxSI = si
          maxDI = di
          max = v
        }
        di++
      }
      si++
    }
    return [maxSI, maxDI]
  }

   isAmbiguous(matrix: (number | null)[][],
              sIndex: number, dIndex: number): boolean {
    const val = matrix[sIndex][dIndex]
    for (let di = 0; di < matrix[sIndex].length; di++) {
      if (matrix[sIndex][di] == val && di != dIndex) { return true }
    }
    for (let si = 0; si < matrix.length; si++) {
      if (matrix[si][dIndex] == val && si != sIndex) { return true}
    }
    return false
  }

   clearRowAndColumn(matrix: (number | null)[][],
                    sIndex: number, dIndex: number) {
    for (let di = 0; di < matrix[sIndex].length; di++) {
      matrix[sIndex][di] = null
    }
    for (let si = 0; si < matrix.length; si++) {
      matrix[si][dIndex] = null
    }
  }

   // #######################################################################
  // #######################################################################
  // #######################################################################

   generateElemMatchMatrix(sourceElems: HTMLElement[],
                          destinationElems: HTMLElement[]) {
    const sourceDestMatchMatrix: number[][] = []
    for (let sourceElem of sourceElems){
      let matrixRow: number[] = []
      sourceDestMatchMatrix.push(matrixRow)
      for (let destElem of destinationElems){
        matrixRow.push(this.elemMatchRanking(sourceElem, destElem))
      }
    }
    return sourceDestMatchMatrix
  }

   // returns a value from 0-1
  // 0: doesn't match at all
  // 1: matches perfectly
  // the values are determined from the turboforms-update attribute,
  // the nodenames, ids and classes
  elemMatchRanking(src: HTMLElement, dest: HTMLElement) {
    let ranking = 0
    if (src.dataset['turboformsUpdate'] == dest.dataset['turboformsUpdate'])
      ranking += 0.6 // always wins
    if (src.nodeName == dest.nodeName)
      ranking += 0.3
    // rest 0.1 is made up by classes and ids
    ranking += 0.07 * this.arrayMatchRanking(src.id.split(" "),
                                             dest.id.split(" "))
    ranking += 0.03 * this.arrayMatchRanking(Array.from(src.classList),
                                             Array.from(dest.classList))
    return Math.round(ranking * 1000) / 1000
  }

   // returns a value from 0-1
  // 0: doesn't match at all
  // 1: matches perfectly
  arrayMatchRanking(array1: string[], array2: string[]) {
    let matchingCount = 0
    for (let val1 of array1) {
      if (array2.indexOf(val1) >= 0){
        matchingCount++
      }
    }
    const elemCount = array1.length + array2.length - matchingCount
    return elemCount == 0 ? 1 : matchingCount / elemCount
  }


   // #######################################################################

   // generates a match matrix of all source-destination form combinations
  // with values:
  // 0: doesn't match at all
  // 1: matches perfectly
  // the values are determined from the form inputs and their values
  generateFormMatchMatrix(sourceForms: HTMLFormElement[],
                          destinationForms: HTMLFormElement[]) {
    const sourceFormsData = sourceForms.map(this.formToDataHash)
    const destinationFormsData = destinationForms.map(this.formToDataHash)
    const sourceDestMatchMatrix: number[][] = []
    for (let sourceFormData of sourceFormsData){
      let matrixRow: number[] = []
      sourceDestMatchMatrix.push(matrixRow)
      for (let destFormData of destinationFormsData){
        matrixRow.push(this.formMatchRanking(sourceFormData, destFormData))
      }
    }
    return sourceDestMatchMatrix
  }

   // returns a value from 0-1
  // 0: doesn't match at all
  // 1: matches perfectly
  // the values are determined from the form inputs and their values
  // ranking is symmetric: form_match_ranking(a, b) = form_match_ranking(b, a)
  formMatchRanking(form1Data: { [name: string]: string | null },
                   form2Data: { [name: string]: string | null }): number {
    // check for every form1Data field the presence in in form2Data
    // the heuristic 'match_points' rates
    // present fields with same values and (high rating)
    // present fields with non-matching values (lower rating)
    let matchPoints = 0
    let matchingFieldCount = 0
    for (let fieldName in form1Data){
      if (form2Data.hasOwnProperty(fieldName)) {
        matchPoints += 0.7
        matchingFieldCount++
        if (form1Data[fieldName] == form2Data[fieldName]) { matchPoints += 0.3 }
      }
    }
    let fieldCount = Object.keys(form1Data).length +
                     Object.keys(form2Data).length -
                     matchingFieldCount
    return fieldCount == 0 ? 1: Math.round(matchPoints*1000/fieldCount)/1000
  }

   formToDataHash(form: HTMLFormElement) {
    let out: { [name: string]: string | null } = {}
    let inputs = form.querySelectorAll('input')
    for (let i = 0; i < inputs.length; i++) {
      out[inputs[i].name] = inputs[i].value
    }
    let textareas = form.querySelectorAll('textarea')
    for (let i = 0; i < textareas.length; i++) {
      out[textareas[i].name] = textareas[i].value
    }
    let selects = form.querySelectorAll('select')
    for (let i = 0; i < selects.length; i++) {
      let option = selects[i].querySelector("option[selected='selected']")
      out[selects[i].name] = option ? (<HTMLOptionElement>option).value : null
    }
    return out
  }
}
