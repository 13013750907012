import ModalComponent from 'components/modal_component';
import Rails from 'rails-ujs';

const handleConfirm = function(this: HTMLElement, element) {
  if (!allowAction(this)) { Rails.stopEverything(element) }
}
const allowAction = (link: HTMLElement) => {
  if (link.getAttribute('data-confirm') === null) { return true }

  ModalComponent.showModal(
    {
      type: 'warning',
      title: link.getAttribute('data-confirm') || undefined,
      text: link.getAttribute('data-confirm-text') || undefined,
      showCancelButton: true,
      confirmButtonText: link.getAttribute('data-accept') || undefined,
      cancelButtonText: link.getAttribute('data-cancel') || undefined
    }
  ).then((result) => {
    if (result.value) {
      link.removeAttribute('data-confirm')
      link.click()
    }
  })
  return false
}

// Add event listener before the other Rails event listeners like the one
// for `method: :delete`
Rails.delegate(document, 'a[data-confirm]', 'click', handleConfirm)

Rails.start()
