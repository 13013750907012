import { Controller } from 'stimulus'
import { setupAxios } from 'helpers/html_head_helper'
import axios from 'axios'
import { RequiredHelper } from 'helpers/required_helper'
import * as HTMLHelper from 'helpers/html_helper'

// TODO kill me

export default class extends Controller {
  readonly searchInputTarget!: HTMLInputElement
  readonly formTarget!: HTMLFormElement

  static targets = ['searchInput']
  initialize() {
    // set crsf header for axios by default
    setupAxios()
  }

  connect() {
    console.log('CONNECT SEARCH CONTROLLER')
    RequiredHelper.checkData(this, 'search-path')

  }

  input(){

  }

  search(e: Event){
    e.preventDefault()
    const searchVal = this.searchInputTarget.value
    const explain =
      (<HTMLInputElement>this.element.querySelector('#search_explain')).checked
    axios.get(
      this.data.get('search-path')!, {
      params: { search: searchVal, explain: explain }
    })
    .then((response)=>{
      console.log(JSON.stringify(response.data, null, 4))
    })
  }

}