import { Controller } from 'stimulus'
import ModalComponent from 'components/modal_component'
import ToastComponent from 'components/toast_component'
import { TimelineLite } from 'gsap/TweenMax'
import axios from 'axios'
import { RequiredHelper } from 'helpers/required_helper'

export default class extends Controller {
  ////////////////// TARGETS ////////////////////
  readonly saveTarget!: HTMLButtonElement
  readonly formTarget!: HTMLFormElement
  readonly nameInputTarget!: HTMLInputElement
  readonly statusTarget!: HTMLSpanElement
  readonly toggleViewableTarget!: HTMLSpanElement
  static targets = ['save', 'form', 'status', 'nameInput', 'toggleViewable']

  connect() {
    RequiredHelper.checkData(this, 'url', 'viewable',
                             'message-text', 'accept-text', 'decline-text')
  }

  ////////////////// UPDATE ////////////////
  // On form submit, update the name on the DB
  update(e: Event) {
    e.preventDefault()
    this.saveTarget.disabled = true
    axios.patch(this.data.get('url')!, new FormData(this.formTarget))
      .then((response) => {
        if (response.status == 200) {
          this.data.set('deck-name', response.data.name)
          let tl = new TimelineLite()
          tl.set(this.statusTarget, { display: 'block' })
            .to(this.nameInputTarget, 0.15, { backgroundColor: '#8ecc8e' })
            .to(this.nameInputTarget, 0.3, { backgroundColor: '#FFFFFF' })
            .set(this.nameInputTarget, { clearProps: 'all' })
        }
      })
  }
  // Checks if the name input has been changed
  nameInputChanged() {
    this.saveTarget.disabled = false
    this.statusTarget.style.display = 'none'
  }

  share(e: Event) {
    e.preventDefault()
    let link = this.data.get('embed-url')
    let linkEmbedCode = `<a href="${this.data.get('embed-url')}" data-controller="embed-document">${this.data.get('deck-name')}</a>
<script id="slidebox" type="text/javascript" src="${this.data.get('pack-url')}"></script>`
    ModalComponent.showModal({
      title: 'Einzeln teilen oder einbetten',
      confirmButtonText: 'Fertig',
      html:
      `<div class="input"
            data-controller="dashboard--box-actions">
        </br>
        <label for="link_embed_input"
               style="text-align:left;">
          Als Link einbetten:
        </label>
        <div class="input-group-bottom-button">
          <textarea id="link_embed_input"
                    name="Link Code"
                    style="overflow:auto; resize:none; white-space:pre;"
                    rows="4">${linkEmbedCode}</textarea>
          <button data-action="dashboard--box-actions#copyText"
                  for="link_embed_input">
            Code kopieren
          </button>
        </div>
        </br>
        <label for="link_share_input"
               style="text-align:left;">
          Als Link teilen:
        </label>
        <div class="input-group-bottom-button">
          <input id="link_share_input"
                 name="Link"
                 value=${link}>
          </input>
          <button data-action="dashboard--box-actions#copyText"
                  for="link_share_input">
            Link kopieren
          </button>
        </div>
      </div>`
    })
  }

  // Grabbing and dragging is handled in deck_controller.ts
  grab(e: Event) {
    e.preventDefault()
  }

  toggleViewable(e: Event) {
    e.preventDefault()

    // Set target value
    let targetValue: 'true' | 'false'
    if (this.data.get('viewable') === 'true') {
      targetValue = 'false'
    } else {
      targetValue = 'true'
    }

    axios.patch(this.data.get('url')!, { viewable: targetValue })
         .then((response) => {
           console.log(response.data.viewable)
           this.data.set('viewable', `${response.data.viewable}`)
           if (response.data.viewable) {
             this.toggleViewableTarget.title = 'Deaktivieren'
           } else {
             this.toggleViewableTarget.title = 'Aktivieren'
             ToastComponent.showToast(
               'info',
               { message: 'Dokument wird nicht mehr in die Galerie angezeigt.' }
             )
           }
         })
         .catch((error) => {
           console.log(error)
         })
  }

  ////////////////// DELETE /////////////////////
  destroy(e: Event) {
    e.preventDefault()
    ModalComponent.showModal(
      {
        type: 'warning',
        title: this.data.get('message-text')!,
        showCancelButton: true,
        confirmButtonText: this.data.get('accept-text')!,
        cancelButtonText: this.data.get('decline-text')!
      }
    ).then((result) => {
      if (result.value) {
        axios.delete(this.data.get('url')!)
          .then((response) => {
            if (response.status == 200) {
              let tl = new TimelineLite()
              let callback = () => { this.element.remove() }
              tl.to(this.element, 0.3, { opacity: 0 })
                .to(this.element, 0.3, { height: 0, margin: 0 })
                .call(callback)
            }
          })
      }
    })
  }
}
