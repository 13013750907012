import { Controller } from "stimulus"
import Modal, { SweetAlertType } from 'components/modal_component'
import Toast from 'components/toast_component'
import Turbolinks from 'turbolinks'

/**
* controls the Toast and Modal presentation of rails flash messages
* that are inserted in the page via the layouts/_flash.html
*/
class FlashController extends Controller {

  get SUB_MESSAGE_START() { return '>>>' }
  get AS_MODAL_WHEN_LONGER_THAN() { return 70 }

  initialize(){
    let type = this.data.get('type')
    let msg = this.data.get('msg') || ''
    let dismissible = false
    if (type && type.indexOf('dismissible_') == 0) {
      type = type.substring(12)
      dismissible = true
    }
    let asModal = false
    let asUpgrade = false
    if (type && type.indexOf('upgrade_') == 0) {
      type = type.substring(6)
      asUpgrade = true
      asModal = true
      dismissible = true
    }
    if (type && type.indexOf('modal_') == 0) {
      type = type.substring(6)
      asModal = true
    }
    // catch long messages from devise and force them to be a modal
    if (type == 'notice' && (msg.length > this.AS_MODAL_WHEN_LONGER_THAN ||
                             msg.indexOf('>>>') >= 0)) {
      dismissible = true
      asModal = true
      type = 'info'
    }
    if (asUpgrade){ this.showUpgradeFlash(<Toast.ToastType>type, msg, dismissible) }
    else if (asModal){ this.showModalFlash(<Toast.ToastType>type, msg, dismissible) }
    else { this.showToastFlash(<Toast.ToastType>type, msg, dismissible) }
    this.element.remove()
  }

  protected showToastFlash(type: Toast.ToastType, message: string,
                           dismissible: boolean): void {
    let splitMessage = this.splitMessage(message)
    Toast.showToast(type, {
      title: splitMessage.title,
      message: splitMessage.text || '',
      timeout: dismissible ? false : 3000,
      progressBar: !dismissible,
      closeOnEscape: !dismissible,
      theme: 'dark'
    })
  }

  protected mapTypesToSWAL(type: Toast.ToastType): SweetAlertType | undefined {
    switch (type) {
      case 'alert': return 'error'
      case 'info': return 'info'
      case 'notice': return undefined
      case 'success': return 'success'
    }
  }

  protected showModalFlash(type: Toast.ToastType, message: string,
                           dismissible: boolean): void {
    let splitMessage = this.splitMessage(message)
    Modal.showModal({
      title: splitMessage.title,
      html: splitMessage.text,
      type: this.mapTypesToSWAL(type),
      customClass: {
        container: `modal-flash ${type}`
      }
    })
  }

  protected showUpgradeFlash(type: Toast.ToastType,
                             message: string,
                             dismissible: boolean): void {
    let splitMessage = this.splitMessage(message)
    Modal.showModal({
      title: splitMessage.title,
      html: splitMessage.text,
      type: 'warning',
      showCancelButton: dismissible,
      confirmButtonText: 'Upgrade <i class="fas fa-star"></i>',
      confirmButtonColor: '#F39237',
      cancelButtonText: 'OK',
      customClass: {
        container: `modal-flash ${type}`
      }
    }).then((result) => {
      if (result.value) {
        Turbolinks.visit('/dashboard/upgrade')
      }
    })
  }


  protected splitMessage(message: string): Messages{
    let startIndex = message.indexOf(this.SUB_MESSAGE_START)
    if (startIndex >= 0) {
      return {
        title: message.substring(0, startIndex).replace(/\n/g, '<br/>'),
        text: message.substring(startIndex + this.SUB_MESSAGE_START.length)
                         .replace(/\n/g, '<br/>')
      }
    } else{
      return { title: message.replace(/\n/g, '<br/>') }
    }
  }
}

type Messages = {
  title: string,
  text?: string
}

export default FlashController
//ES5 window Module (used in styleguide)
if (!(<any>window).Modules){ (<any>window).Modules = {} }
(<any>window).Modules.FlashController = FlashController
