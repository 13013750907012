// Helpers for HTML modification

export function insertAfter(newNode, referenceNode) {
  referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling)
}

export function getAbsoluteHeight (el: HTMLElement): number{
  var styles = window.getComputedStyle(el);
  var margin = parseFloat(<string>styles['marginTop']) +
               parseFloat(<string>styles['marginBottom']);
  return Math.ceil(el.offsetHeight + margin);
}

///////////////////////////
// runtime type checking //
///////////////////////////

// HTMLElement
export function assureHTMLElement(el: any): HTMLElement{
  if (!(el instanceof HTMLElement)){
    console.error('passed Element is no HTMLElement') }
  return <HTMLElement>el }
// HTMLInputElement
export function assureHTMLInputElement(el: any): HTMLInputElement{
  if (!(el instanceof HTMLInputElement)){
    console.error('passed Element is no HTMLInputElement') }
  return <HTMLInputElement>el }
// HTMLButtonElement
export function assureHTMLButtonElement(el: any): HTMLButtonElement{
  if (!(el instanceof HTMLButtonElement)){
    console.error('passed Element is no HTMLButtonElement') }
  return <HTMLButtonElement>el }
// HTMLFormElement
export function assureHTMLFormElement(el: any): HTMLFormElement{
  if (!(el instanceof HTMLFormElement)){
    console.error('passed Element is no HTMLFormElement') }
  return <HTMLFormElement>el }
// HTMLImageElement
export function assureHTMLImageElement(el: any): HTMLImageElement{
  if (!(el instanceof HTMLImageElement)){
    console.error('passed Element is no HTMLImageElement') }
  return <HTMLImageElement>el }
// HTMLDivElement
export function assureHTMLDivElement(el: any): HTMLDivElement{
  if (!(el instanceof HTMLDivElement)){
    console.error('passed Element is no HTMLDivElement') }
  return <HTMLDivElement>el }
// HTMLSpanElement
export function assureHTMLSpanElement(el: any): HTMLSpanElement{
  if (!(el instanceof HTMLSpanElement)){
    console.error('passed Element is no HTMLSpanElement') }
  return <HTMLSpanElement>el }
// HTMLLinkElement
export function assureHTMLLinkElement(el: any): HTMLLinkElement{
  if (!(el instanceof HTMLLinkElement)){
    console.error('passed Element is no HTMLLinkElement') }
  return <HTMLLinkElement>el }
// HTMLTableElement
export function assureHTMLTableElement(el: any): HTMLTableElement{
  if (!(el instanceof HTMLTableElement)){
    console.error('passed Element is no HTMLTableElement') }
  return <HTMLTableElement>el }
// HTMLOListElement
export function assureHTMLOListElement(el: any): HTMLOListElement{
  if (!(el instanceof HTMLOListElement)){
    console.error('passed Element is no HTMLOListElement') }
  return <HTMLOListElement>el }
// HTMLUListElement
export function assureHTMLUListElement(el: any): HTMLUListElement{
  if (!(el instanceof HTMLUListElement)){
    console.error('passed Element is no HTMLUListElement') }
  return <HTMLUListElement>el }
// HTMLCanvasElement
export function assureHTMLCanvasElement(el: any): HTMLCanvasElement{
  if (!(el instanceof HTMLCanvasElement)){
    console.error('passed Element is no HTMLCanvasElement') }
  return <HTMLCanvasElement>el }
  // HTMLSelectElement
export function assureHTMLSelectElement(el: any): HTMLSelectElement{
  if (!(el instanceof HTMLSelectElement)){
    console.error('passed Element is no HTMLSelectElement') }
  return <HTMLSelectElement>el }
// HTMLOptionElement
export function assureHTMLOptionElement(el: any): HTMLOptionElement{
  if (!(el instanceof HTMLOptionElement)){
    console.error('passed Element is no HTMLOptionElement') }
  return <HTMLOptionElement>el }
// SVGElement
export function assureSVGElement(el: any): SVGElement{
  if (!(el instanceof SVGElement)){
    console.error('passed Element is no SVGElement') }
  return <SVGElement>el }
