import { Controller } from 'stimulus'
import Modal from 'components/modal_component'
import ToastComponent from 'components/toast_component'
import Turbolinks from 'turbolinks'
import axios from 'axios'
import { setupAxios } from 'helpers/html_head_helper'
import Rails from 'rails-ujs'

export default class extends Controller {

  initialize() {
    setupAxios()
  }

  saveSettings(e: Event) {
    e.preventDefault()
    let btnEl = <HTMLButtonElement>(e.currentTarget)
    btnEl.disabled = true
    btnEl.classList.add('loading')
    Rails.fire(document.getElementsByTagName('form')[0], 'submit')
  }

  shareModal(e:Event) {
    e.preventDefault()
    let iFrameCode = `<iframe name="slidebox" data-controller="embed" src="${this.data.get('show-box-url')}"></iframe>
<script id="slidebox" type="text/javascript" src="${this.data.get('pack-url')}"></script>`
    let link = this.data.get('embeds-show-box-url')

    Modal.showModal({
      title: 'Teilen oder einbetten',
      confirmButtonText: 'Fertig',
      html:
        `<div class="input"
              data-controller="dashboard--box-actions">
          </br>
          <label for="iframe_embed_input"
                 style="text-align:left;">
            Als iFrame einbetten:
          </label>
          <div class="input-group-bottom-button">
            <textarea id="iframe_embed_input"
                      name="iFrame Code"
                      style="overflow:auto; resize:none; white-space:pre;"
                      rows="4">${iFrameCode}</textarea>
            <button data-action="dashboard--box-actions#copyText"
                    for="iframe_embed_input">
              Code kopieren
            </button>
          </div>
          </br>
          <label for="link_share_input"
                 style="text-align:left;">
            Als Link teilen:
          </label>
          <div class="input-group-bottom-button">
            <input id="link_share_input"
                   name="Link"
                   value=${link}>
            </input>
            <button data-action="dashboard--box-actions#copyText"
                    for="link_share_input">
              Link kopieren
            </button>
          </div>
        </div>`
    })
  }

  copyText(e: Event) {
    let inputId: string = (<HTMLButtonElement>e.target).getAttribute('for')!
    let inputEl = <HTMLInputElement | HTMLTextAreaElement>
                  document.getElementById(inputId)

    inputEl.select()
    document.execCommand("copy")
    ToastComponent.showToast(
      'info',
      { message: inputEl.name + ' wurde kopiert' }
    )
  }

  destroy(e: Event) {
    e.preventDefault()
    Modal.showModal({
      title: 'Möchtest du diese Galerie wirklich löschen?',
      html:
        `Alle Dokumente innerhalb der Galerie werden unwiederruflich
        gelöscht. Falls du die Galerie per iFrame in deine Seite eingebettet
        hast, wird dies nicht mehr funktionieren.
        </br></br>
        Wenn du sie wirklich löschen möchtest, bestätige dies durch
        Eingabe des Namens:
        <p class='text--center text--danger'>${this.data.get('name')}</p>`,
      type: 'error',
      input: 'text',
      inputPlaceholder: this.data.get('name')!,
      inputValidator: (result) => {
        if (!result) {
          return 'Kein Name angegeben'
        } else if (result != this.data.get('name')) {
          return 'Falscher Name'
        } else {
          return null
        }
      },
      showCancelButton: true,
      confirmButtonColor: '#D63230',
      confirmButtonText: 'Unwiederruflich Löschen',
      cancelButtonText: 'Abbrechen',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return new Promise(() => {
          axios.delete(this.data.get('url')!)
            .then((response) => {
              Modal.showModal({
                type: 'info',
                title: 'Galerie wurde gelöscht',
              }).then(()=>{
                Turbolinks.visit(
                  response.data.location, { action: 'replace' }
                )
              })
            })
            .catch((error) => {
              Modal.showModal({
                type: 'error',
                title: 'Ein Fehler ist aufgetreten.',
                text: error
              })
            })
        })
      }
    })
  }
}
