import { Controller } from 'stimulus'
import { TimelineLite } from 'gsap/TweenMax'

export default class extends Controller {
  overlay = document.createElement('div')
  tl = new TimelineLite()
  overlayClickCallback = this.toggle.bind(this)

  initialize() {
    this.overlay.classList.add('sidebar-body-overlay')
    document.body.appendChild(this.overlay)
    this.overlay.addEventListener('click', this.overlayClickCallback)
    this.overlay.addEventListener('touchend', this.overlayClickCallback)
  }

  destroy() {
    this.overlay.removeEventListener('click', this.overlayClickCallback)
    this.overlay.removeEventListener('touchend', this.overlayClickCallback)
  }

  toggle() {
    this.element.classList.toggle('expand')
    if (this.element.classList.contains('expand')) {
      this.tl.clear()
      this.overlay.classList.add('cover')
      this.tl.from(this.overlay, 0.3, {opacity: '0'})
    } else {
      this.tl.clear()
      this.tl.to(this.overlay, 0.3, {opacity: '0'})
             .set(this.overlay, {clearProps: 'opacity'})
             .call(() => this.overlay.classList.remove('cover'))
    }
  }
}
