import { Controller } from 'stimulus'

export default class extends Controller {
  readonly inputTarget!: HTMLInputElement
  readonly containerTarget!: HTMLElement
  readonly uncheckedTarget!: HTMLLabelElement
  readonly checkedTarget!: HTMLLabelElement
  static targets = ['input', 'container', 'unchecked', 'checked']

  switchBg: HTMLElement = document.createElement("div")
  checked: boolean = this.inputTarget.checked

  initialize() {
    this.switchBg.classList.add('switch-bg')
    this.containerTarget.appendChild(this.switchBg)
  }

  connect() {
    this.initSwitchBg()
  }

  initSwitchBg() {
    if(this.uncheckedTarget.clientWidth == 0) {
      window.setTimeout(this.initSwitchBg.bind(this), 50)
    } else {
      this.toggle()
    }
  }

  toggle() {
    this.checked = this.inputTarget.checked
    if (this.checked) {
      this.switchBg.style.width = this.checkedTarget.clientWidth + 'px'
      this.switchBg.style.left = this.uncheckedTarget.clientWidth + 6 + 'px'
      this.switchBg.classList.add('gold')
    } else {
      this.switchBg.style.width = this.uncheckedTarget.clientWidth + 'px'
      this.switchBg.style.left = '4px'
      this.switchBg.classList.remove('gold')
    }
  }
}
