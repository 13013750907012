import { Controller } from "stimulus"
import * as Cookies from 'js-cookie'

export default class extends Controller {
  static targets = [ 'allowedHint', 'forbiddenHint', 'button', 'savedHint',
                     'allowBtnLabel', 'forbidBtnLabel',
                     'savedMessage' ]
  readonly allowedHintTarget!: HTMLElement
  readonly forbiddenHintTarget!: HTMLElement
  readonly buttonTarget!: HTMLElement
  readonly allowBtnLabelTarget!: HTMLElement
  readonly forbidBtnLabelTarget!: HTMLElement
  readonly savedMessageTarget!: HTMLElement
  connect() {
    const allowed = Cookies.get('allow_cookies') != 'no'
    this.allowedHintTarget.style.opacity = allowed ? '1' : '0'
    this.forbiddenHintTarget.style.opacity = allowed ? '0' : '1'
    this.allowBtnLabelTarget.style.opacity = allowed ? '0' : '1'
    this.forbidBtnLabelTarget.style.opacity = allowed ? '1' : '0'
    this.savedMessageTarget.style.opacity = '0'
  }
  toggle() {
    const allow = Cookies.get('allow_cookies') == 'no'
    if (allow){
      Cookies.set('allow_cookies', 'yes', { expires: 365 })
      this.forbiddenHintTarget.style.opacity = '0'
      this.allowBtnLabelTarget.style.opacity = '0'
    }
    else {
      Cookies.set('allow_cookies', 'no')
      this.allowedHintTarget.style.opacity = '0'
      this.forbidBtnLabelTarget.style.opacity = '0'
    }
    this.savedMessageTarget.style.opacity = '1'
    setTimeout(()=>{
      this.savedMessageTarget.style.opacity = '0'
      if (allow) {
        this.allowedHintTarget.style.opacity = '1'
        this.forbidBtnLabelTarget.style.opacity = '1'
      }
      else {
        this.forbiddenHintTarget.style.opacity = '1'
        this.allowBtnLabelTarget.style.opacity = '1'
      }
    }, 1000)
  }
}