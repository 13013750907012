import { Controller } from "stimulus"
import axios from 'axios'
import { setupAxios } from 'helpers/html_head_helper'

export default class extends Controller {
  readonly inputTarget!: HTMLInputElement
  readonly inputWrapperTarget!: HTMLDivElement
  readonly ribbonTarget!: HTMLDivElement
  readonly ribbonTextTarget!: HTMLSpanElement
  readonly netCostTarget!: HTMLDivElement
  readonly vatCostTarget!: HTMLDivElement
  readonly totalCostTarget!: HTMLDivElement
  readonly submitTarget!: HTMLButtonElement
  readonly noticeTarget!: HTMLSpanElement
  static targets = ['input', 'inputWrapper', 'ribbon', 'ribbonText', 
    'netCost', 'vatCost', 'totalCost', 'submit', 'notice']
  
  private scheduledCheck: any
  
  initialize() {
    setupAxios()
  }

  ///////////////////////// ACTIONS /////////////////////////
  inputChanged() {
    clearTimeout(this.scheduledCheck)
    
    this.inputWrapperTarget.classList.add("loading")
    this.inputWrapperTarget.classList.remove("valid")
    this.inputWrapperTarget.classList.remove("invalid")
    
    this.scheduledCheck = setTimeout(() => {
      this.checkCoupon(this.inputTarget.value)
    }, 1000)
  }

  ///////////////////////// METHODS /////////////////////////
  checkCoupon(code: string) {
    if (code != "") {
      // Call api to check code
      axios.post("/coupon", { code: code }).then((result) => {
        if (result.data.valid == true) {
          this.inputWrapperTarget.classList.remove("loading")
          this.inputWrapperTarget.classList.add("valid")
          this.applyDiscount(result.data.percent_off)
          this.submitTarget.disabled = false
        } else {
          this.inputWrapperTarget.classList.remove("loading")
          this.inputWrapperTarget.classList.add("invalid")
          this.submitTarget.disabled = true
          this.removeDiscount()
        }
      }).catch((error) => {
        console.log(error)
      })
    } else {
      // Reset if input is empty
      this.inputWrapperTarget.classList.remove("loading")
      this.ribbonTarget.classList.add("hidden")
      this.ribbonTextTarget.innerText = ""
      this.submitTarget.disabled = false
      this.removeDiscount()
    }
  }

  applyDiscount(percent: number) {
    // Ribbon
    this.ribbonTarget.classList.remove("hidden")
    this.ribbonTextTarget.innerText = `- ${percent}%`
    
    // Price display
    let totalPrice = this.totalPrice()
    let newTotalPrice = this.newTotalPrice(percent)
    // -> Subtotal
    if (this.isReverseCharge()) {
      this.netCostTarget.innerHTML =
        `<span class="old">${totalPrice.toFixed(2)}</span>
         <span class="new">${newTotalPrice.toFixed(2)}</span> €`
    } else {
      this.netCostTarget.innerHTML =
        `<span class="old">${this.netPrice(totalPrice).toFixed(2)}</span>
         <span class="new">${this.netPrice(newTotalPrice).toFixed(2)}</span> €`
      this.vatCostTarget.innerHTML =
        `<span class="old">${this.vatPrice(totalPrice).toFixed(2)}</span>
         <span class="new">${this.vatPrice(newTotalPrice).toFixed(2)}</span> €`
    }
    // -> Total
    this.totalCostTarget.innerHTML =
      `<span class="old">${totalPrice.toFixed(2)}</span>
       <span class="new">${newTotalPrice.toFixed(2)}</span> €`
    
    // Notice
    this.noticeTarget.style.display = 'inline-block'
  }
  
  removeDiscount() {
    // Ribbon
    this.ribbonTarget.classList.add("hidden")
    this.ribbonTextTarget.innerText = ""
    
    // Price display
    let totalPrice = this.totalPrice()
    // -> Subtotal
    if (this.isReverseCharge()) {
      this.netCostTarget.innerHTML = `${totalPrice.toFixed(2)} €`
    } else {
      this.netCostTarget.innerHTML = `${this.netPrice(totalPrice).toFixed(2)} €`
      this.vatCostTarget.innerHTML = `${this.vatPrice(totalPrice).toFixed(2)} €`
    }
    // -> Total
    this.totalCostTarget.innerHTML = `${totalPrice.toFixed(2)} €`

    // Notice
    this.noticeTarget.style.display = 'none'
  }

  //////////////////// HELPERS //////////////////////
  isReverseCharge() {
    return this.element.hasAttribute("data-discount-reverse-charge")
  }
  
  taxAmount() { return parseInt(this.data.get("tax-amount")!) / 100 }

  netPrice(totalPrice: number) {
    let taxAmount = this.taxAmount()
    return totalPrice / (taxAmount + 1)
  }

  vatPrice(totalPrice: number) {
    return totalPrice - this.netPrice(totalPrice)
  }

  totalPrice() {
    return parseFloat(this.data.get("total")!)
  }

  newTotalPrice(percent: number) {
    let totalPrice = this.totalPrice()
    return totalPrice - totalPrice * (percent / 100)
  }
}
