import 'lazysizes'
import { Controller } from "stimulus"

export default class extends Controller {
  readonly containerTarget!: HTMLElement
  static targets = ['container']
  connect() {
    // Adds Lazyload class to trigger lazysizes library
    this.element.classList.add("lazyload")
    this.element.addEventListener("lazyloaded", this.loadVideo.bind(this))
    this.setContainerStyles()
  }

  loadVideo() {
    const src = this.data.get("src")
    if (src) {
      const iframe = this.createIframe(src)
      this.containerTarget.appendChild(iframe)
    } else {
      console.warn(
        `Warning no src given for ${this.element}! 
        Use the data-lazy-video-scr attribute`
      )
    }
  }

  createIframe(src: string) {
    let iframe = document.createElement("iframe")
    iframe.src = "https://player.vimeo.com/video/" + src
    iframe.allow = "autoplay; fullscreen"
    iframe.allowFullscreen = true,
    iframe.frameBorder = "0",
    iframe.style.position = "absolute"
    iframe.style.top = "0"
    iframe.style.left = "0"
    iframe.style.width = "100%"
    iframe.style.height = "100%"
    return iframe
  }

  setContainerStyles() {
    this.containerTarget.style.padding = "56.25% 0 0 0"
    this.containerTarget.style.position = "relative"
  }
}