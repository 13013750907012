// import Flatpickr from "flatpickr"
// import German from 'flatpickr/dist/l10n/de'
// import { CustomLocale } from "flatpickr/dist/types/locale"
// require('flatpickr/dist/flatpickr.css')

export default class DateTimePickerHandler {
  // Singelton Example: private instance and private constructor
  private static instance: DateTimePickerHandler

  // locale: "default" | CustomLocale | undefined = undefined

  private constructor() {}

  static start() {
    // if (!DateTimePickerHandler.instance) {
    //   DateTimePickerHandler.instance = new DateTimePickerHandler()
    //   DateTimePickerHandler.instance.setup()
    // }
  }

  // private setup() {
  //   document.addEventListener('turbolinks:load', () => {
  //     this.setLocale()
  //     this.checkDateInputs()
  //   })
  // }

  // setLocale() {
  //   if (document.body.dataset.locale == 'de') {
  //     this.locale = German.de
  //   } else if ( document.body.dataset.locale == 'en') {
  //     this.locale = 'default' // en
  //   } else {
  //     this.locale = 'default'
  //   }
  // }

  // checkDateInputs() {
  //   let dateInputs = document.getElementsByClassName('input date_picker')
  //   for (let i=0; i < dateInputs.length; i++) {
  //     let input = dateInputs[i].getElementsByTagName('input')[0]
  //     Flatpickr(input, {
  //       "locale": this.locale
  //     })
  //   }
  // }
}
