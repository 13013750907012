import { Controller } from 'stimulus'
import { RequiredHelper } from 'helpers/required_helper'

export default class extends Controller {
  stripe!: stripe.Stripe
  elements!: stripe.elements.Elements
  card!: stripe.elements.Element

  readonly errorTarget!: HTMLElement
  readonly formTarget!: HTMLFormElement
  readonly tokenTarget!: HTMLInputElement
  readonly submitTarget!: HTMLInputElement
  static targets = ['error', 'form', 'token', 'submit']

  initialize() {
    RequiredHelper.checkData(this, 'pubkey')
  }

  connect() {
    if (typeof(window['Stripe']) != 'undefined') {
      this.initStripe()
    }
    document.addEventListener('stripe-loaded', this.initStripe.bind(this))
  }

  disconnect() {
    document.removeEventListener('stripe-loaded', this.initStripe.bind(this))
  }

  initStripe() {
    this.stripe = Stripe(this.data.get('pubkey')!, { locale: 'de' })
    this.elements = this.stripe.elements()
    this.createCardElement()
    this.initCardErrors()
    this.initSubmitHandler()
  }

  createCardElement() {
    const style = {
      base: {
        // Add your base input styles here. For example:
        fontSize: '15px',
        color: '#202428',
        lineHeight: '42px'
      }
    }
    this.card = this.elements.create('card', { style })
    this.card.mount('#card-element')
  }

  initCardErrors() {
    (<HTMLElement>(<any>this.card)).addEventListener('change',
                                                    ({error}:any) => {
      if (error) {
        this.errorTarget.classList.add('content')
        this.errorTarget.textContent = error.message
      } else {
        this.errorTarget.classList.remove('content')
        this.errorTarget.textContent = ''
      }
    })
  }

  initSubmitHandler() {
    this.formTarget.addEventListener('submit', async (event) => {
      event.preventDefault()
      const {token, error} = await this.stripe.createToken(this.card)
      if (error) {
        // Inform the customer that there was an error.
        this.errorTarget.classList.add('content')
        this.errorTarget.textContent = <string>error.message
        this.submitTarget.disabled = false
        this.submitTarget.classList.remove('loading')
      } else {
        // Send the token to your server.
        this.errorTarget.classList.remove('content')
        this.stripeTokenHandler(token)
      }
    })
  }

  stripeTokenHandler(token: stripe.Token | undefined) {
    if (token) {
      this.tokenTarget.value = token.id
      this.formTarget.submit()
    } else {
      console.error('There is no token from stripe')
    }
  }
}
