import { Controller } from 'stimulus'
import Turbolinks from 'turbolinks'

export default class extends Controller {
  initialize() {
    console.log('leads controller init')
  }

  changeBox(e: Event) {
    Turbolinks.visit((<HTMLOptionElement>e.target)!.value)
  }
}
