import { Controller } from "stimulus"

export class RequiredHelper {

  /** pass your controller and all the targets that you want to be assured of.
  * each passed target can be a string or an object of the form
  * { 'target_name': {count: 2, clazz: 'HTMLElement'} }.
  * The object form defines also, how often you expect the target to
  * be in the DOM and what class it should be (both are optional)*/
  static checkTargets(controller: Controller,
               ...targets: (string | DetailedTarget)[]): boolean {
    let all_ok = true
    let controllerName = controller.context.identifier
    targets.forEach((target) => {
      if (typeof(target) == 'string'){
        if(!controller.targets.find(target)){
          all_ok = false
          console.error(`${controllerName}-controller: can't find ` +
                        `data-target='${controllerName}.${target}'`)
        }
      }
      else{
        Object.keys(target).forEach( (t) => {
          let opts = target[t]
          let foundTargets = controller.targets.findAll(t)
          if (opts['count'] != undefined){
            if (foundTargets.length != opts['count']){
              all_ok = false
              console.error(
                `${controllerName}-controller: did not find exactly `+
                `${opts['count']} occurences of data-target=` +
                `'${controllerName}.${t}', occurences counted: ` +
                `${foundTargets.length}`)
            }
          }
          else if (foundTargets.length != 1){
            all_ok = false
            console.error(`${controllerName}-controller: can't find ` +
                          `data-target='${controllerName}.${t}'`)
          }
          if (opts['clazz'] == undefined){ return }
          foundTargets.forEach( (ft) => {
            let ok = true
            switch (opts['clazz']){
              case 'HTMLElement':{
                ok = (ft instanceof HTMLElement); break; }
              case 'HTMLInputElement':{
                ok = (ft instanceof HTMLInputElement); break; }
              case 'HTMLButtonElement':{
                ok = (ft instanceof HTMLButtonElement); break; }
              case 'HTMLFormElement':{
                ok = (ft instanceof HTMLFormElement); break; }
              case 'HTMLImageElement':{
                ok = (ft instanceof HTMLImageElement); break; }
              case 'HTMLDivElement':{
                ok = (ft instanceof HTMLDivElement); break; }
              case 'HTMLSpanElement':{
                ok = (ft instanceof HTMLSpanElement); break; }
              case 'HTMLLinkElement':{
                ok = (ft instanceof HTMLLinkElement); break; }
              case 'HTMLTableElement':{
                ok = (ft instanceof HTMLTableElement); break; }
              case 'HTMLOListElement':{
                ok = (ft instanceof HTMLOListElement); break; }
              case 'HTMLUListElement':{
                ok = (ft instanceof HTMLUListElement); break; }
              case 'HTMLCanvasElement':{
                ok = (ft instanceof HTMLCanvasElement); break; }
              case 'HTMLSelectElement':{
                ok = (ft instanceof HTMLSelectElement); break; }
              case 'HTMLOptionElement':{
                ok = (ft instanceof HTMLOptionElement); break; }
              default: {
                all_ok = false
                console.error('unknown class')
              }
            }
            if (!ok){
              all_ok = false
              console.error(`${controllerName}-controller: found target ` +
                            `data-target='${controllerName}.${t}' `+
                            `not of expected type '${opts['clazz']}'`)
            }
          })
        })
      }
    })
    return all_ok
  }

  /** pass your controller and all the data names
  * that you want to be assured of. */
  static checkData(controller: Controller,
                   ...data: (string)[]): boolean {
    let all_ok = true
    let controllerName = controller.context.identifier
    data.forEach((dt) => {
      if (typeof(dt) == 'string'){
        if(!controller.data.get(dt)){
          all_ok = false
          console.error(`${controllerName}-controller: can't find ` +
                        `'data-${controllerName}-${dt}' data-entry`)
        }
      }
    })
    return all_ok
  }
}

type DetailedTarget = { [key:string]:{count?: number, clazz?: string} }
