import { Controller } from 'stimulus'
import { setupAxios } from 'helpers/html_head_helper'
import Modal from 'components/modal_component'
import raterjs from 'rater-js'
import axios from 'axios'

export default class extends Controller {
  readonly starsTarget!: HTMLElement
  static targets = ['stars']
  rater!: Rater
  rating!: number | undefined
  url = this.data.get('url')!

  initialize() {
    setupAxios()
  }

  connect() {
    if (this.data.get('rating')) {
      this.rating = parseInt(<string>this.data.get('rating'))
    }

    this.rater = raterjs({
      element: this.starsTarget,
      rating: this.rating,
      starSize: 48,
      rateCallback: (rating, done) => {
        this.rater.setRating(rating)
        let data = new FormData
        data.append('user[rating]', rating.toString())
        axios.patch(this.url, data).then(
          (response) => {
            let title
            let text
            if (rating > 3) {
              title = 'Dankeschön!\n Wir freuen uns das es dir gefällt.'
            } else if (rating == 3) {
              title = 'Danke für dein Feedback!'
              text = 'Über Anregungen, was wir in Zukunft noch besser machen können, würden wir uns sehr freuen.'
            } else {
              title = 'Schade das es dir nicht so gut gefällt.'
              text = 'Teile uns doch bitte mit, was der Grund dafür ist.'
            }
            Modal.showModal({
              title: title,
              text: text
            })
          }
        ).catch(
          (response) => { console.log(response) }
        )
        if (done) { done() }
      }
    })
  }
}
