import { Controller } from 'stimulus'
import * as Dropzone from 'dropzone'
import { RequiredHelper } from 'helpers/required_helper'
import { DirectUpload } from '@rails/activestorage'
// Css imported in application.ts

export default class extends Controller {
  readonly fileInputFieldTarget!: HTMLInputElement
  static targets = ['fileInputField']
  dropzone: Dropzone | null = null
  url!: string
  name!: string
  form!: HTMLFormElement
  submit!: HTMLInputElement


  /////////////////////// Lifecycle //////////////////////////
  initialize() {
    const dz = Dropzone
    dz.autoDiscover = false
    this.url = this.fileInputFieldTarget.dataset.directUploadUrl!
    this.name = this.fileInputFieldTarget.name!
    this.form = this.fileInputFieldTarget.form!
    this.fileInputFieldTarget.remove()
    this.submit =
      <HTMLInputElement>this.form.querySelector('button[type="submit"]')!
  }

  connect() {
    RequiredHelper.checkData(this, 'accepted-types')
    let acceptedTypes = this.data.get('accepted-types')!
    let maxUploadCount = parseInt(this.data.get('max-upload-count')!)
    let currentUploadCount = parseInt(this.data.get('current-upload-count')!)
    this.dropzone = new Dropzone(<HTMLElement>this.element, {
      url: this.url,
      maxFilesize: 1000, // Mb
      maxFiles: maxUploadCount - currentUploadCount,
      autoProcessQueue: false,
      dictDefaultMessage: 'Dokumente hier ablegen oder <u>auswählen</u>',
      dictFallbackMessage: 'Dein Browser wird nicht mehr unterstützt',
      dictInvalidFileType: 'Dateiformat wird nicht unterstützt',
      dictResponseError: 'Serverfehler {{statusCode}}',
      dictCancelUpload: 'Abbrechen',
      dictCancelUploadConfirmation: 'sicher?',
      dictUploadCanceled: 'Upload abgebrochen',
      dictRemoveFile: "<i class='far fa-times'></i>",
      dictMaxFilesExceeded: 'Limit erreicht ({{maxFiles}})',
      dictFileTooBig: 'Datei zu groß (max: {{maxFilesize}} Mb)',
      uploadMultiple: true,
      addRemoveLinks: true,
      acceptedFiles: acceptedTypes
    })
    let self = this
    let dz = this.dropzone! // hold it for access in closure below
    this.dropzone.on('addedfile', (file) => {
      setTimeout(()=>{ // dz.getAcceptedFiles lags behind the addedfile event
        if (dz.getAcceptedFiles().includes(file)) {
          this.uploadFile(file)
          this.submit.disabled = true
          this.submit.classList.remove('shine')
          this.submit.classList.remove('bling')
        }
      }, 0)
    })
    this.dropzone.on('removedfile', (file) => {
      console.log('removed file: '+file.name)
      file.status = "success"
      this.dropzone!.emit("complete", file)
      if (file['uploader']) { file['uploader'].xhr.abort() }
    })
    let showButton = () => {
      if (this.submit.disabled) { // only if some files are accepted
        this.submit.disabled = false
        this.submit.classList.remove('hidden')
        this.submit.classList.add('shine')
        setTimeout(() => { this.submit.classList.add('bling') }, 300)
      }
    }
    this.dropzone.on('error', (e) => {
      showButton()
    })
    this.dropzone.on('queuecomplete', () => {
      showButton()
      // If the form should be automatically submitted when done uploading:
      // this.submit.click()
    })
  }

  disconnect() {
    this.dropzone!.destroy()
    this.dropzone = null
  }

  ///////////////////////// Methods //////////////////////////
  uploadFile(file) {
    file.uploader = new Uploader(file, this.url)
    file.uploader.upload().then((blob:any) => {
      const hiddenField: HTMLInputElement = document.createElement('input')
      let cancelBtn =
        file.previewElement.getElementsByClassName('dz-remove')[0]
      cancelBtn.style.display = 'none'
      hiddenField.setAttribute('type', 'hidden')
      hiddenField.setAttribute('value', blob.signed_id)
      hiddenField.name = this.name
      this.form.appendChild(hiddenField)
      file.status = "success"
      this.dropzone!.emit("success", file)
      this.dropzone!.emit("complete", file)
    }).catch((error) => {
      file.status = "error"
      this.dropzone!.emit("error", file, error)
      this.dropzone!.emit("complete", file)
    })
  }
}

class Uploader {
  file: any
  url: string
  directUpload: DirectUpload
  xhr: XMLHttpRequest | undefined
  preview: HTMLElement

  constructor(file: File, url: string) {
    this.file = file
    this.url = url
    this.directUpload = new DirectUpload(this.file, this.url, this)
    this.preview = this.file.previewElement
  }

  upload() {
    return new Promise((resolve, reject) => {
      this.directUpload.create((error, blob) => {
        if (error) {
          reject(error)
        } else {
          resolve(blob)
        }
      })
    })
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.xhr = xhr

    let cancelBtn =
      <HTMLElement>this.preview.getElementsByClassName('dz-remove')[0]
    cancelBtn.style.display = 'block'

    xhr.upload.addEventListener("progress",
      event => this.directUploadDidProgress(event))
  }

  directUploadDidProgress(event) {
    // Uses event.loaded and event.total to update the progress bar in each
    // separate preview box
    let percent = ((event.loaded / event.total) * 100).toFixed(1)
    let progress =
      <HTMLElement>this.preview.getElementsByClassName('dz-upload')[0]
    progress.style.width = percent + '%'
  }
}
